import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Typography, Box } from '@mui/material';

const HomePage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Container maxWidth="sm">
            <Box sx={{ mt: 3, textAlign: 'center' }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Welcome to Board Game Tracker
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/create-game')}
                    sx={{ mt: 2, mb: 1 }}
                    fullWidth
                >
                    Create Game
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate('/join-game')}
                    sx={{ mt: 1 }}
                    fullWidth
                >
                    Join Game
                </Button>
            </Box>
        </Container>
    );
};

export default HomePage;