import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Components/HomePage';
import CreateGame from './Components/CreateGame';
import JoinGame from './Components/JoinGame';
import PlayerDetails from './Components/PlayerDetails';
import MainGame from "./Components/MainGame";

const App: React.FC = () => {
    return (
        <Router>
            <div>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/create-game" element={<CreateGame />} />
                    <Route path="/join-game" element={<JoinGame />} />
                    <Route path="/main-game/:playerId" element={<MainGame />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;