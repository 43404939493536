import React, { useState, ChangeEvent, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { joinGame } from '../apiService';
import { Container, TextField, Button, Typography, Box } from '@mui/material';

interface JoinGameRequest {
    gameCode: string;
    playerName: string;
}

const JoinGame: React.FC = () => {
    const [joinGameRequest, setJoinGameRequest] = useState<JoinGameRequest>({ gameCode: '', playerName: '' });
    const navigate = useNavigate();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setJoinGameRequest({ ...joinGameRequest, [name]: value });
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            const response = await joinGame(joinGameRequest);
            navigate(`/main-game/${response.data.id}`);
        } catch (error) {
            console.error('Error joining game:', error);
        }
    };

    return (
        <Container maxWidth="sm">
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Join Game
                </Typography>
                <TextField
                    label="Game Code"
                    name="gameCode"
                    value={joinGameRequest.gameCode}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                />
                <TextField
                    label="Player Name"
                    name="playerName"
                    value={joinGameRequest.playerName}
                    onChange={handleChange}
                    fullWidth
                    required
                    margin="normal"
                />
                <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                    Join Game
                </Button>
            </Box>
        </Container>
    );
};

export default JoinGame;