import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPlayerDetails } from '../apiService';
import { Player } from '../Models/Player';
import { PlayerStats } from '../Models/PlayerStats';
import { Game } from '../Models/Game';
import Armoury from './Armoury';
import PlayerDetails from './PlayerDetails';
import { Container, Button, Typography, Box, Tabs, Tab } from '@mui/material';

const MainGame: React.FC = () => {
    const { playerId } = useParams<{ playerId: string }>();

    const [player, setPlayer] = useState<Player | null>(null);
    const [stats, setStats] = useState<PlayerStats>({
        id: 0,
        cash: 0,
        rank: 1,
        threat: 0,
        heat: 0,
        luck: 0
    });
    const [game, setGame] = useState<Game | null>(null);
    const [activeTab, setActiveTab] = useState<'stats' | 'armoury'>('stats');

    useEffect(() => {
        const fetchPlayerDetails = async () => {
            try {
                if (playerId != null && !player) {
                    const response = await getPlayerDetails(playerId);
                    setPlayer(response.data);
                    setStats(response.data.playerStats);
                    setGame(response.data.game);
                }
            } catch (error) {
                console.error('Error fetching player details:', error);
            }
        };

        fetchPlayerDetails();
    }, [playerId, setPlayer, player]);

    function handleStatsChange(stats: PlayerStats) {
        setStats(stats);
    }

    function handlePlayerUpdate(player: Player) {
        setPlayer(player);
        setStats(player.playerStats);
    }

    return (
        <Container>
            <Typography variant="h1">{game?.name}</Typography>
            <Typography variant="body1">Player Name: {player?.name}</Typography>
            <Typography variant="body1">Game Code: {player?.game.accessCode}</Typography>
            <Box>
                <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
                    <Tab label="Stats" value="stats" />
                    <Tab label="Armoury" value="armoury" />
                </Tabs>
            </Box>
            <Box>
                {activeTab === 'stats' && player && (
                    <PlayerDetails player={player} stats={stats} onStatsChange={handleStatsChange} />
                )}
                {activeTab === 'armoury' && player && (
                    <Armoury player={player} onPlayerUpdate={handlePlayerUpdate} />
                )}
            </Box>
        </Container>
    );
};

export default MainGame;