import React, { useEffect, useState } from 'react';
import { Player } from '../Models/Player';
import { Weapon } from '../Models/Weapon';
import { PlayerWeapon } from '../Models/PlayerWeapon';
import { getWeapons, buyWeapon } from '../apiService';
import { Container, Typography, Table, TableBody, TableCell, TableHead, TableRow, Button, Alert } from '@mui/material';

interface ArmouryProps {
    player: Player;
    onPlayerUpdate: (player: Player) => void;
}

const Armoury: React.FC<ArmouryProps> = ({ player, onPlayerUpdate }) => {
    const [weapons, setWeapons] = useState<Weapon[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchWeapons = async () => {
            try {
                const weapons = await getWeapons();
                setWeapons(weapons);
            } catch (err) {
                setError('Failed to fetch weapons');
                console.error('Error fetching weapons:', err);
            }
        };

        fetchWeapons();
    }, []);

    const handleBuyWeapon = async (weapon: Weapon) => {
        if (player.playerStats.rank < weapon.rank) {
            alert('Your rank is too low to buy this weapon.');
            return;
        }

        if (player.playerStats.cash < weapon.price) {
            alert('You do not have enough cash to buy this weapon.');
            return;
        }

        if (!Array.isArray(player.playerWeapons)) {
            player.playerWeapons = [];
        }

        if (player.playerWeapons.some(pw => pw.weaponId === weapon.id)) {
            alert('You already own this weapon.');
            return;
        }

        try {
            const playerResponse = await buyWeapon(player.id, weapon.id);
            const newPlayerWeapon: PlayerWeapon = {
                playerId: player.id,
                weaponId: weapon.id,
                weapon: weapon,
                player: player
            };
            const updatedPlayer = {
                ...player,
                playerStats: playerResponse.playerStats,
                playerWeapons: [...player.playerWeapons, newPlayerWeapon]
            };
            alert('Weapon purchased successfully!');
            onPlayerUpdate(updatedPlayer);
        } catch (error: any) {
            console.error('Error buying weapon:', error);
            alert(error.response.data);
        }
    };

    if (error) {
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Container>
            <Typography variant="h3">Armoury</Typography>
            <Typography variant="body1">Welcome to the armoury! Here you can buy weapons.</Typography>
            <Typography variant="body1">Current Cash: {player.playerStats.cash}</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Rank</TableCell>
                        <TableCell>Threat</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {weapons.map(weapon => {
                        const ownsWeapon = player.playerWeapons.some(pw => pw.weaponId === weapon.id);
                        return (
                            <TableRow key={weapon.id}>
                                <TableCell>{weapon.name}</TableCell>
                                <TableCell>{weapon.rank}</TableCell>
                                <TableCell>{weapon.threat}</TableCell>
                                <TableCell>{weapon.price}</TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() => handleBuyWeapon(weapon)}
                                        disabled={ownsWeapon}
                                        variant="contained"
                                        color="primary"
                                    >
                                        {ownsWeapon ? 'Owned' : 'Buy'}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </Container>
    );
};

export default Armoury;