// src/Utils/TransformResponse.ts
import { Weapon } from '../Models/Weapon';

export const transformWeaponsResponse = (data: any): Weapon[] => {
    console.log("transformWeaponsResponse", data);

    return data.map((item: any) => ({
        id: item.id,
        name: item.name,
        rank: item.rank,
        threat: item.threat,
        price: item.price,
        playerWeapons: item.playerWeapons ? item.playerWeapons : []
    }));
};