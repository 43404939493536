import React, { useState, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { createGame } from '../apiService';
import { Container, TextField, Button, Typography, Box } from '@mui/material';

const CreateGame: React.FC = () => {
    const [gameName, setGameName] = useState('');
    const [accessCode, setAccessCode] = useState('');
    const [playerName, setPlayerName] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        try {
            const response = await createGame({ name: gameName, accessCode: accessCode, playerName: playerName });
            console.log('Game created:', response.data);
            navigate(`/main-game/${response.data.id}`);
        } catch (error) {
            console.error('Error creating game:', error);
        }
    };

    return (
        <Container maxWidth="sm">
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Create Game
                </Typography>
                <TextField
                    label="Game Name"
                    value={gameName}
                    onChange={(e) => setGameName(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                />
                <TextField
                    label="Room Code"
                    value={accessCode}
                    onChange={(e) => setAccessCode(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                />
                <TextField
                    label="Player Name"
                    value={playerName}
                    onChange={(e) => setPlayerName(e.target.value)}
                    fullWidth
                    required
                    margin="normal"
                />
                <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                    Create Game
                </Button>
            </Box>
        </Container>
    );
};

export default CreateGame;