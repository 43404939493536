import React from 'react';
import { updatePlayerStats } from '../apiService';
import { Player } from '../Models/Player';
import { PlayerStats } from '../Models/PlayerStats';
import { Container, TextField, Button, Typography, Box } from '@mui/material';

interface PlayerDetailsProps {
    player: Player;
    stats: PlayerStats;
    onStatsChange: (stats: PlayerStats) => void;
}

const PlayerDetails: React.FC<PlayerDetailsProps> = (props: PlayerDetailsProps) => {
    const { player, stats, onStatsChange } = props;

    const handleUpdate = async () => {
        if (player && stats) {
            try {
                let updatedStats = { ...stats };
                updatedStats.playerId = player.id;
                await updatePlayerStats(updatedStats);
            } catch (error) {
                console.error('Error updating player stats:', error);
            }
        }
    };

    const handleStatsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (stats) {
            const { name, value } = e.target;
            onStatsChange({ ...stats, [name]: parseInt(value) });
        }
    }

    if (!player) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Container maxWidth="sm">
            <Box sx={{ mt: 3 }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Player Stats
                </Typography>
                {stats && (
                    <Box>
                        <TextField
                            label="Cash"
                            name="cash"
                            type="number"
                            value={stats.cash}
                            onChange={handleStatsChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Rank"
                            name="rank"
                            type="number"
                            value={stats.rank}
                            onChange={handleStatsChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Threat"
                            name="threat"
                            type="number"
                            value={stats.threat}
                            onChange={handleStatsChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Heat"
                            name="heat"
                            type="number"
                            value={stats.heat}
                            onChange={handleStatsChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Luck"
                            name="luck"
                            type="number"
                            value={stats.luck}
                            onChange={handleStatsChange}
                            fullWidth
                            margin="normal"
                        />
                    </Box>
                )}
                <Button onClick={handleUpdate} variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
                    Update
                </Button>
            </Box>
        </Container>
    );
};

export default PlayerDetails;