import axios from 'axios';
import {PlayerStats} from "./Models/PlayerStats";
import {Weapon} from "./Models/Weapon";
import {transformWeaponsResponse} from "./Utils/TransformResponse";
import {Player} from "./Models/Player";

const API_URL = 'https://boardgameapi.yagasoft.co.uk/api';

interface GamePayload {
    name: string;
    accessCode: string;
    playerName: string;
}

interface JoinGameRequest {
    gameCode: string;
    playerName: string;
}

export const createGame = (game: GamePayload) => {
    return axios.post(`${API_URL}/game/create`, game);
};

export const joinGame = (joinGameRequest: JoinGameRequest) => {
    return axios.post(`${API_URL}/game/join`, joinGameRequest);
};

export const getGame = (accessCode: string) => {
    return axios.get(`${API_URL}/game/${accessCode}`);
};

export const updatePlayerStats = (playerStats: PlayerStats) => {
    return axios.put(`${API_URL}/game/updateStats`, playerStats);
};

export const getPlayerDetails = (playerId: string) => {
    return axios.get(`${API_URL}/game/player/${playerId}`);
};

export const getWeapons = async (): Promise<Weapon[]> => {
    const response = await axios.get<Weapon[]>(`${API_URL}/weapon/all`);
    return transformWeaponsResponse(response.data);
};

export const buyWeapon = async (playerId: number, weaponId: number): Promise<Player> => {
    const response = await axios.post(`${API_URL}/weapon/${weaponId}/addToPlayer/${playerId}`, { weaponId });
    console.log('buyWeapon response', response);
    return response.data;
};